import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseChart } from '@app/components/common/charts/BaseChart';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { ACTUAL_DATA_EPOCH_MS } from '@app/lib/contracts';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Simulate } from 'react-dom/test-utils';
import compositionEnd = Simulate.compositionEnd;
import { GenericCard } from '@app/components/vaults-dashboard/genericCard/GenericCard';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { DataPoint } from '@app/lib/data';

interface IProductChartProps {
  dayHistories: Array<{ timestamp: number; value: number }>;
  weekHistories: Array<{ timestamp: number; value: number }>;
  monthHistories: Array<{ timestamp: number; value: number }>;
  month3Histories: Array<{ timestamp: number; value: number }>;
  yearHistories: Array<{ timestamp: number; value: number }>;
  allHistories: Array<{ timestamp: number; value: number }>;

  btcDayHistories: Array<{ timestamp: number; value: number }>;
  btcWeekHistories: Array<{ timestamp: number; value: number }>;
  btcMonthHistories: Array<{ timestamp: number; value: number }>;
  btcMonth3Histories: Array<{ timestamp: number; value: number }>;
  btcYearHistories: Array<{ timestamp: number; value: number }>;
  btcAllHistories: Array<{ timestamp: number; value: number }>;
  spinning: boolean;
}

export const ProductChart: React.FC<IProductChartProps> = (props: IProductChartProps) => {
  const [timeframe, setTimeFrame] = useState(0);
  const theme = useAppSelector((state) => state.theme.theme);

  const getEarliestTimestamp = (timeframe: number) => {
    if (props.dayHistories.length == 0 || props.btcDayHistories.length == 0) {
      return 0;
    }

    return timeframe == 0
      ? Math.max(props.dayHistories[0].timestamp, props.btcDayHistories[0].timestamp)
      : timeframe == 1
      ? Math.max(props.weekHistories[0].timestamp, props.btcWeekHistories[0].timestamp)
      : timeframe == 2
      ? Math.max(props.monthHistories[0].timestamp, props.btcMonthHistories[0].timestamp)
      : timeframe == 3
      ? Math.max(props.month3Histories[0].timestamp, props.btcMonth3Histories[0].timestamp)
      : timeframe == 4
      ? Math.max(props.yearHistories[0].timestamp, props.btcYearHistories[0].timestamp)
      : Math.max(props.allHistories[0].timestamp, props.btcAllHistories[0].timestamp);
  };

  const getLatestTimestamp = (timeframe: number) => {
    if (props.dayHistories.length == 0 || props.btcDayHistories.length == 0) {
      return 0;
    }
    return timeframe == 0
      ? Math.min(
          props.dayHistories[props.dayHistories.length - 1].timestamp,
          props.btcDayHistories[props.btcDayHistories.length - 1].timestamp,
        )
      : timeframe == 1
      ? Math.min(
          props.weekHistories[props.weekHistories.length - 1].timestamp,
          props.btcWeekHistories[props.btcWeekHistories.length - 1].timestamp,
        )
      : timeframe == 2
      ? Math.min(
          props.monthHistories[props.monthHistories.length - 1].timestamp,
          props.btcMonthHistories[props.btcMonthHistories.length - 1].timestamp,
        )
      : timeframe == 3
      ? Math.min(
          props.month3Histories[props.month3Histories.length - 1].timestamp,
          props.btcMonth3Histories[props.btcMonth3Histories.length - 1].timestamp,
        )
      : timeframe == 4
      ? Math.min(
          props.yearHistories[props.yearHistories.length - 1].timestamp,
          props.btcYearHistories[props.btcYearHistories.length - 1].timestamp,
        )
      : Math.min(
          props.allHistories[props.allHistories.length - 1].timestamp,
          props.btcAllHistories[props.btcAllHistories.length - 1].timestamp,
        );
  };

  const restrictDataToRange = (
    data: Array<{ timestamp: number; value: number }>,
    earliestTimestamp: number,
    latestTimestamp: number,
    msg: string,
  ) => {
    return data.filter((entry) => entry.timestamp >= earliestTimestamp && entry.timestamp <= latestTimestamp);
  };

  const returnsSelector = (timeframe: number) => {
    return timeframe == 0
      ? props.dayHistories
      : timeframe == 1
      ? props.weekHistories
      : timeframe == 2
      ? props.monthHistories
      : timeframe == 3
      ? props.month3Histories
      : timeframe == 4
      ? props.yearHistories
      : props.allHistories;
  };

  const btcReturnsSelector = (timeframe: number) => {
    return timeframe == 0
      ? props.btcDayHistories
      : timeframe == 1
      ? props.btcWeekHistories
      : timeframe == 2
      ? props.btcMonthHistories
      : timeframe == 3
      ? props.btcMonth3Histories
      : timeframe == 4
      ? props.btcYearHistories
      : props.btcAllHistories;
  };

  const option = {
    color: themeObject[theme]['success'],
    animationDuration: 700,
    tooltip: {
      formatter: function (params: any) {
        const date = new Date(params[0].axisValue);
        const dateFormatted = date.toLocaleTimeString(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });

        let tooltipText = '';

        // Loop through all params and check series name
        params.forEach((param: any) => {
          if (param.seriesName === 'relative gain') {
            tooltipText += `<span style='color: ${
              themeObject[theme]['success']
            }'>Product Return: </span><b>${param.value[1].toLocaleString(undefined, {
              minimumFractionDigits: 1,
            })} %</b><br/>`;
          } else if (param.seriesName === 'BTC') {
            tooltipText += `<span style='color: ${
              themeObject[theme]['primary']
            }'>BTC Return: </span><b>${param.value[1].toLocaleString(undefined, {
              minimumFractionDigits: 1,
            })} %</b><br/>`;
          }
        });

        // Add the date at the end
        tooltipText += dateFormatted;

        return tooltipText;
      },
      textStyle: {
        align: 'right',
      },
      trigger: 'axis',
    },
    xAxis: {
      // data: selector(timeframe)?.map((item) =>
      //   new Date(item.x).toLocaleTimeString(undefined, {
      //     year: 'numeric',
      //     month: 'short',
      //     day: 'numeric',
      //     hour: '2-digit',
      //     minute: '2-digit',
      //   }),
      // ),
      boundaryGap: false,
      type: 'time',
      axisLabel: {
        show: true,
        // // interval: 'auto', // timeframe == 0 ? 99 : timeframe == 3 ? 79 : 55,
        // interval: 99,
        hideOverlap: true,
        rotate: 25,
        textStyle: {
          fontSize: 9,
        },

        formatter: (value: any) => {
          // Format the timestamp value as needed
          return new Date(value).toLocaleTimeString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            // minute: '2-digit',
          });
        },
      },
      axisTick: {
        show: false,
      },
      // nameLocation: 'middle',
    },
    yAxis: {
      type: 'value',
      // name: '% CHANGE',
      nameLocation: 'middle',
      nameGap: 40,
      nameRotate: 90,
      scale: true,
      animation: true,
      animationDurationUpdate: 0,
      splitLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
    },
    // grid: {
    //   left: 65,
    //   right: 150,
    //   top: 20,
    //   bottom: 30,
    // },
    grid: {
      containLabel: true,
      left: 65,
      bottom: 10,
      right: 30,
    },
    series: [
      {
        name: 'relative gain',
        data: restrictDataToRange(
          returnsSelector(timeframe),
          getEarliestTimestamp(timeframe),
          getLatestTimestamp(timeframe),
          'Product',
        ).map((item) => ({
          value: [item.timestamp, item.value],
        })),
        type: 'line',
        smooth: true,
        smoothMonotone: 'x',
        symbol: 'none',
        animation: true,
        animationDuration: 1000,
        animationDurationUpdate: 0,
      },
      {
        name: 'BTC',
        data: restrictDataToRange(
          btcReturnsSelector(timeframe),
          getEarliestTimestamp(timeframe),
          getLatestTimestamp(timeframe),
          'BTC',
        ).map((item) => ({
          value: [item.timestamp, item.value],
        })),
        type: 'line',
        smooth: true,
        smoothMonotone: 'x',
        symbol: 'none',
        animation: true,
        animationDuration: 1000,
        animationDurationUpdate: 0,
        color: themeObject[theme]['primary'],
      },
    ],

    // visualMap: {
    //   show: false, // Wether to show the legend or not (default: true)
    //
    //   dimension: 0, // Which dimension to use to create the visual map. Use the index of the dimension
    //   pieces: [
    //     {
    //       min: -9999, // Normally not needed but doesn't work without that (1)
    //       lt: ACTUAL_DATA_EPOCH_MS,
    //       // color: themeObject[theme]['textLight'],
    //       color: themeObject[theme]['primary'],
    //     },
    //     {
    //       gte: ACTUAL_DATA_EPOCH_MS,
    //       color: themeObject[theme]['success'],
    //     },
    //   ],
    //   outOfRange: {
    //     color: 'antiquewhite',
    //   },
    // },
  };

  return (
    <BaseCard padding="0 0 0.0rem">
      <BaseRow
        gutter={[20, 20]}
        align="middle"
        style={{ justifyContent: 'space-between', paddingLeft: '2vh', paddingRight: '2vh', paddingTop: '2vh' }}
      >
        <BaseCol>
          <h1 style={{ fontWeight: 'bold' }}>{`Historical Returns`}</h1>
        </BaseCol>
        <BaseCol>
          <BaseRadio.Group defaultValue={timeframe} buttonStyle="outline" size="small">
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(0);
              }}
              value={0}
            >
              1d
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(1);
              }}
              value={1}
            >
              1w
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(2);
              }}
              value={2}
            >
              1m
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(3);
              }}
              value={3}
            >
              3m
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(4);
              }}
              value={4}
            >
              1y
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(5);
              }}
              value={5}
            >
              All
            </BaseRadio.Button>
          </BaseRadio.Group>
        </BaseCol>
      </BaseRow>

      <BaseSpin spinning={props.spinning}>
        {props.spinning ? null : <BaseChart option={option} height="24rem" />}
      </BaseSpin>
    </BaseCard>
  );
};

export default ProductChart;
